
.datatable{
    height: 600px;
    padding: 20px;

    .datatableTitle{
        width: 100%;
        font-size: 25px;
        color: gray;
        margin-bottom: 10px;
        align-items: center;
        justify-content: space-between;
        display: flex;


        
        .link{
            text-decoration: none;
            color: green;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid green;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }


    .cellWidthImg{
        display: flex;
        align-items: center;

        .cellImg{
            width: 32px;
            height: 32px;border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }
    }

    .cellWithStatus{
        padding: 5px;
        border-radius: 5px;

        &.Active{
            background-color: rgba(73, 253, 7, 0.348) ;
            color: rgba(4, 225, 33, 0.986);
            
        }

        &.Pending{
            background-color: rgba(222, 251, 3, 0.419);
            color: rgba(178, 194, 6, 0.996);
        }

        &.Inactive{
            background-color:  rgba(255, 0, 0, 0.221);
            color: crimson;
        }
    }

    .cellAction{
        display: flex;
        align-items: center;
        gap: 16px;


        .viewButton{
            padding: 2px 5px;
            border-radius: 5px ;
            color: darkblue;
            border: 1px solid darkblue;
            cursor: pointer;

            &:hover {
                background-color: darkblue;
                color: white;
              }

        }

        .deleteButton{
            padding: 2px 5px;
            border-radius: 5px ;
            color: crimson;
            border: 1px solid crimson;
            cursor: pointer;

            &:hover {
                background-color: crimson;
                color: white;
              }

        }
    }
}