.addQR {
    display: flex;
  
    .addQRContainer {
      flex: 6;
      margin-left: 190px;
  
      .listTitle {
        font-weight: 900;
        color: gray;
        margin-bottom: 15px;
        margin-top: 100px;
        font-size: 20px;
      }
  
      // Form styling
      .qrForm {
        padding-right: 100px;
        display: flex;
        flex-direction: column;
  
        .formGroup {
          margin-bottom: 15px;
  
          label {
            font-weight: 600;
            margin-bottom: 5px;
          }
  
          input {
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 100%;
          }
        }
  
        button {
          padding: 10px;
          width: 100px;
          margin-bottom: 20px;

          background-color: #4caf50;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: #45a049;
          }
        }
      }
    }
  }
  