.sidebar{
    flex:1;
    border-right: 0.5px solid lightgray;
    min-height: 100vh;
    background-color: rgb(0, 9, 252) ;

    .Logo-main{
        width: 200px;
        height: 200px;
    }

    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        

        .logo{
            font-size: 35px;
            font-weight: bolder ;
            color: #ffffff;
        }
    }

    hr{
        height: 0;
        border: 0.5px solid lightgray;
    }

    .center{
        padding-left: 10px ;
        


        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            

            .title{
                font-size: 12px;
                font-weight: bold;
                color: rgb(255, 255, 255);
                margin-top: 15px;
                margin-bottom: 5px;
            }

            li{
                display: flex;
                align-items: center;
                padding: 20px;
                cursor: pointer;
                font-size: 24px; 
                margin-bottom: 20px;

                
                

                &:hover{
                    &:hover {
                        background-color: #000000;
                        transition: background-color 0.5s ease; 
                        border-radius: 5px;
                        
                      }
                }
                .icon{
                    font-size: 23px;
                    color: #ffffff;

                   
                }

                span{
                    font-size: 20px;
                    // font-weight: 600;
                    color: rgb(255, 255, 255);
                    margin-left: 10px;

                    &:hover{
                        color: white;
                    }
                }
            }
        }
    }

    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            cursor: pointer;
            margin: 5px;

            &:nth-child(1){
                background-color: whitesmoke;
            }

            &:nth-child(2){
                background-color: #333;
            }

           

        }
    }
}