
.datatable{
    height: 600px;
    padding: 20px;
    

    .datatableTitle{
        width: 100%;
        font-size: 25px;
        color: rgb(0, 9, 252);;
        margin-bottom: 10px;
       margin-left: -90px;
        justify-content: space-between;
        display: flex;

        .title-name{
            color: rgb(0, 9, 252);
        }

        .buttonDownload {
            display: inline-block;
            position: relative;
            padding: 10px 25px;
            background-color: #FA0F00;
            color: white;
            font-family: sans-serif;
            text-decoration: none;
            font-size: 0.9em;
            text-align: center;
            text-indent: 15px;
            border: none;
           }
           
           
           
           .buttonDownload:before, .buttonDownload:after {
            content: ' ';
            display: block;
            position: absolute;
            left: 15px;
            top: 52%;
           }
           
           .buttonDownload:before {
            width: 10px;
            height: 2px;
            border-style: solid;
            border-width: 0 2px 2px;
           }
           
           .buttonDownload:after {
            width: 0;
            height: 0;
            margin-left: 3px;
            margin-top: -7px;
            border-style: solid;
            border-width: 4px 4px 0 4px;
            border-color: transparent;
            border-top-color: inherit;
            animation: downloadArrow 1s linear infinite;
            animation-play-state: paused;
           }
           
           .buttonDownload:hover:before {
            border-color: #cdefbd;
           }
           
           .buttonDownload:hover:after {
            border-top-color: #cdefbd;
            animation-play-state: running;
           }
           
           @keyframes downloadArrow {
            0% {
             margin-top: -7px;
             opacity: 1;
            }
           
            0.001% {
             margin-top: -15px;
             opacity: 0.4;
            }
           
            50% {
             opacity: 1;
            }
           
            100% {
             margin-top: 0;
             opacity: 0.4;
            }
           }

        .button {
            --color: rgb(0, 9, 252);
            padding: 0.5em 1.2em;
            background-color: transparent;
            border-radius: .3em;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            transition: .5s;
            font-weight: 400;
            font-size: 17px;
            border: 1px solid;
            font-family: inherit;
            text-transform: uppercase;
            color: var(--color);
            z-index: 1;
           }
           
           .button::before, .button::after {
            content: '';
            display: block;
            width: 50px;
            height: 50px;
            transform: translate(-50%, -50%);
            position: absolute;
            border-radius: 50%;
            z-index: -1;
            background-color: var(--color);
            transition: 1s ease;
           }
           
           .button::before {
            top: -1em;
            left: -1em;
           }
           
           .button::after {
            left: calc(100% + 1em);
            top: calc(100% + 1em);
           }
           
           .button:hover::before, .button:hover::after {
            height: 410px;
            width: 410px;
           }
           
           .button:hover {
            color: rgb(255, 255, 255);
           }
           
           .button:active {
            filter: brightness(.8);
           }
           

        .link{
            text-decoration: none;
            color: green;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid green;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }


    .cellWidthImg{
        display: flex;
        align-items: center;

        .cellImg{
            width: 32px;
            height: 32px;border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }
    }

    .cellWithStatus{
        padding: 5px;
        border-radius: 5px;

        &.Active{
            background-color: rgba(73, 253, 7, 0.348) ;
            color: rgba(4, 225, 33, 0.986);
            
        }

        &.Pending{
            background-color: rgba(222, 251, 3, 0.419);
            color: rgba(178, 194, 6, 0.996);
        }

        &.Inactive{
            background-color:  rgba(255, 0, 0, 0.221);
            color: crimson;
        }
    }

    .cellAction{
        display: flex;
        align-items: center;
        gap: 16px;


        .viewButton{
            padding: 2px 5px;
            border-radius: 5px ;
            color: darkblue;
            border: 1px solid darkblue;
            cursor: pointer;

            &:hover {
                background-color: darkblue;
                color: white;
              }

        }

        .deleteButton{
            padding: 2px 5px;
            border-radius: 5px ;
            color: crimson;
            border: 1px solid crimson;
            cursor: pointer;

            &:hover {
                background-color: crimson;
                color: white;
              }

        }
    }
}