
.home{
    display: flex;

    .homeContainer{
        flex:6;
        margin-left: 190px;

        .widgets , .charts{
            display: flex;
            padding: 20px;
            gap: 20px;
        }

        .listContainer{
            -webkit-box-shadow: -10px 10px 12px -7px rgba(0,0,0,0.33);
            -moz-box-shadow: -10px 10px 12px -7px rgba(0,0,0,0.33);
            box-shadow: -10px 10px 12px -7px rgba(0,0,0,0.33);
             padding: 10px;
             margin: 20px;

             .listTitle{
                font-weight: 500;
                color: gray;
                margin-bottom: 15px;
             }
        }
    }
}