.container img {
    margin-top: 30px;
    width: 300px;
    height: auto;
    max-height: 200px;
    align-self: center;
    animation: bounce 1s ease infinite;
    margin-left: 41%;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-top: 30px;
  }
  
  .text {
    color: #3c009d;
    font-size: 65px;
    font-weight: 900;
  }
  
  .underline {
    width: 61px;
    height: 6px;
    background: #3c009d;
    border-radius: 9px;
  }
  
  .inputs {
    margin-top: 55px;
    margin-bottom: 20px;
    font-display: column;
    gap: 25px;
  }
  
  .input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 300px;
    height: 40px;
    background: #eaeaea;
    border-radius: 6px;
    margin-bottom: 30px;
  }
  
  .input input {
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
  }
  
  .icon1 {
    padding: 20px;
  }
  
  .submit-container {
    display: flex;
    gap: 20px;
    margin: 10px auto;
    margin-left: 45%;
  }
  
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    color: #fff;
    background: #4c00b4;
    border-radius: 10px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
  }
  
  .submit:hover {
    background: #5f1aff;
  }
  
  /* Shaking animation */
  .shake {
    animation: shake 0.4s ease-in-out;
  }
  
  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(-10px);
    }
    40% {
      transform: translateX(10px);
    }
    60% {
      transform: translateX(-10px);
    }
    80% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }
  